 <template>
	<section class="section-wrapper" v-background="value.background.path">
		<base-heading :heading="`${prefix}.section_title`" :subheading="`${prefix}.section_subtitle`" white/>
		<grid-template cols="4">
			<div class="icon-card" v-for="(icon, id) in value.icons" :key="icon.icons_title" v-animate="`opacity${id}`">
				<cms-text :value="`${prefix}.icons.${id}.icons_title`" :props="{variant: 'small-heading', color: 'secondary'}"/>
				<base-svg :name="icon.icons_icon" :class="`${icon.icons_icon}-icon`"/>
				<cms-text :value="`${prefix}.icons.${id}.icons_content`" :props="{variant: 'small', color: 'primary', tag: 'p'}"/>
			</div>
		</grid-template>
	</section>
</template>

<script>
import CmsText from '~/cms/front/components/CmsText/CmsText.vue'
import BaseHeading from '../../components/molecules/headings/BaseHeading/BaseHeading.vue'
import GridTemplate from '../../templates/GridTemplate.vue'
export default {
	components: {
		GridTemplate,
		BaseHeading,
		CmsText
	},
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>
.section-wrapper {
	padding: 4rem 0;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	z-index: 5;

	&::before {
		content: '';
		top: 0;
		left: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		background: $secondary;
		opacity: .8;
		z-index: -1;
	}

	@include media-breakpoint-up(xl) {
		padding: 8rem 0;
	}
	.icon-card {
		box-shadow: 0 3px 12px rgba($secondary, 0.2);
		padding: 2rem;
		display: flex;
		border-radius: 6px;
		flex-direction: column;
		justify-content: space-between;
		transition: .3s ease-in;
		align-items: center;
		gap: 2rem;
		text-align: center;
		background: rgba($white, .85);
        height: 100%;

		@include media-breakpoint-up(xl) {
			&:hover {
				background: rgba($white, .95);
				.star-icon {
					transform: rotate(120deg);
					fill: $yellow;
				}
				.team-icon {
					::v-deep .team {
						opacity: 1;
					}
					transform: scale(1.2);
					fill: $secondary;
				}
				.training-icon {
					::v-deep .text {
						transform: scaleX(1);
						path {
							fill: $red;
						}
					}
				}
				.technology-icon {
					::v-deep .gear {
						transform: rotate(120deg);
					}
					::v-deep .circle {
						transform: rotate(-120deg);
						path {
							fill: $secondary;
						}
					}
				}
				.environment-icon {
					::v-deep .plant {
						transform: scale(1);
						fill: $green;
					}
				}
				.handshake-icon {
					::v-deep .star {
						transform: translateY(-1rem) scale(1.3);
						path {
							fill: $yellow;
						}
					}
				}
				.lightbulb-icon {
					::v-deep path {
						fill: $yellow;
					}
					::v-deep .light {
						transform: translateY(-10px) scale(1.1);
					}
					::v-deep .lightbulb {
						transform: scale(1.1);
					}
				}
				.growth-icon {
					::v-deep .improvement {
						transform: scale(1);
						path {
							fill: $green;
						}
					}
				}
			}
		}

		.svg-icon {
			height: 100px;
			overflow: visible;
			@include media-breakpoint-up(xl) {

				::v-deep .team {
					opacity: 0;
				}
				::v-deep .text {
					transition: .3s ease-in;
					transform-origin: 20% 50%;
					transform: scaleX(.3);
				}
				::v-deep .gear, ::v-deep .circle {
					transform-origin: 50% 50%;
					transition: .3s ease-in;
				}
				::v-deep .plant {
					transform: scale(0.3);
					transform-origin: 50% 65%;
				}
				::v-deep .star {
					transform-origin: 50% 50%;
					transition: .3s ease-in;
				}
				::v-deep .light, ::v-deep .lightbulb {
					transform-origin: bottom;
					transition: .3s ease-in;
				}
				::v-deep .improvement {
					transform-origin: bottom;
					transition: .3s ease-in;
					transform: scale(.7);
				}
			}
		}
	}

}
</style>
